<template>
  <div class="cool-login" v-loading="isLoading" element-loading-text="登录中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.3)">
    <div class="page">
      <div class="login" >
        <img src="../../assets/login/Login-your-account.png" alt="">
        <div class="login_title">账号登陆</div>
        <el-form ref="loginForm" :model="formData" label-width="0" style="margin-top:15px">
          <el-form-item prop="account" verify required>
            <el-input 
              placeholder="请输入账号" 
              v-model.trim="formData.account" 
              clearable>
            </el-input>
          </el-form-item>  
          <el-form-item prop="password" verify required>
            <el-input 
              placeholder="请输入密码" 
              @keyup.enter.native="loginSubmit"
              v-model.trim="formData.password" 
              :type="passwordType"
              :key="passwordType" 
              ref="password">
                <i slot="suffix" 
                  @click="showPwd" 
                  class="iconfont" 
                  style="cursor:pointer;"
                  :class="passwordType === 'password' ? loginIcons['eye-open'] : loginIcons['eye'] ">
                </i>
            </el-input>
          </el-form-item>  
          <el-form-item prop="validateCode" :rules="codeRules" class="validateCode">  <!-- required :verify="verifyCode" -->
            <el-input 
              placeholder="请输入验证码" 
              v-model.trim="formData.validateCode" 
              @keyup.enter.native="enterKey"
              maxlength="4"
              clearable> 
              <template slot="append">
                <div 
                  style="width:115px;height:38px;cursor:pointer;"
                  @click="getVerifyCode">
                    <img
                      ref="authcode"
                      class="authcode-img"
                      :src="validateImgUrl"
                      title="点击更换验证码"
                      height="38"
                      width="100%"
                    >
                </div>
              </template>
            </el-input>
          </el-form-item>  
          <div class="remember-box">
            <el-checkbox 
              v-model="isRememberPassword" 
              class="rememberPwd-check">
                记住密码
            </el-checkbox>
          </div>
          <el-form-item>
            <div class="login-button" @click="loginSubmit">
              <div class="login-button_text">登录</div>
            </div>
          </el-form-item>
        </el-form>   
          <div style="margin-top:130px;color:#9b9b9b;text-align:center;letter-spacing:1px;">
            <div>得乐喜信息科技有限公司</div>
            <div>珠海市横琴新区环岛东路1889号20栋314室</div>
            <div>电话：0756-882 2005</div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'login',
  data(){
    const validateAuthcode = (rule, value, callback) => {
      if(!value) {
        callback(new Error('验证码不能为空'))
      }else if(value.length < 4){
        callback(new Error('请输入4位验证码'))
      }else{
        callback()
      }  
    }
    return{
      validateImgUrl: 'https://vitejs.cn/logo.svg',
      formData: {
        account: '',
        password: '',
        validateCode: '',
      },
      isLoading: false,
      loginIcons: {
        'account': 'icon-user',
        'password': 'icon-pwd', 
        'eye': 'icon-eye',
        'eye-open': 'icon-eye-open',
      },
      passwordType: 'password',
      isRememberPassword: false,
      codeRules: [
        {
          required: true,
          // message: '该项不能为空',
          // trigger: ['blur', 'change']
          trigger: 'blur',
          validator: validateAuthcode,
        }
      ],
    }
  },
  created(){
    this.handelRemember()
    this.getVerifyCode()
  },
  methods: {
    loginSubmit(){
      this.$refs.loginForm.validate(async valid => {
        console.log('formData:', this.formData)
        if(!valid) return false
        this.isLoading = true
        try{
          const userInfo = await this.$store.dispatch('user/login', this.formData)
          // 保存账户信息 
          this.setAccountInfo()
          
          this.$router.push('/main')
          setTimeout(() => {
            this.isLoading = false
          }, 300)
        }catch(e){
          setTimeout(() => this.isLoading = false, 500)
          this.getVerifyCode()
        }
      })
    },
    // keyup.enter 事件
    enterKey(event) {
      const componentName = this.$options.name
      if (componentName == 'login') {
        const code = event.keyCode
          ? event.keyCode
          : event.which
          ? event.which
          : event.charCode
        if (code == 13) {
            this.loginSubmit()
        }
      }
    },
    setAccountInfo(){
      this.$cookies.setIsRemember(this.isRememberPassword)
      if(this.isRememberPassword){
        const {account, password} = this.formData
        let accountInfo = {
          account,
          password,
        }
        this.$cookies.setAccountInfo(accountInfo)
      }
    },
    handelRemember(){
      let isRememberPassword = this.$cookies.getIsRemember()
      if(isRememberPassword){
        this.isRememberPassword = isRememberPassword
        const accountInfo = this.$cookies.getAccountInfo()
        const {account, password} = accountInfo
        this.formData = {
          account,
          password,
        }
      }
    },
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = ''
      } else {
        this.passwordType = 'password'
      }
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    getVerifyCode(){
      this.validateImgUrl = process.env.VUE_APP_BASE_API +
        '/api/user/login/createCode?account=' +
        this.formData.account +
        '&solt=' +
        Math.random()
    },
  },
}
</script>

<style lang="less" scoped>
.cool-login{
  height: 100vh;
  // height: 946px;
  .page{
    height: 100%;
    background: url('../../assets/login/login-bg.jpg') center no-repeat;
    background-size: 100% 100%;
    position: relative;
  }
  .login{
    width: 344px;
    height: 432px;
    box-sizing: border-box;
    position: absolute;
    // right: 10rem;
    left: 68%;
    top: 50%;
    transform: translateY(-60%);
    opacity: 0.9;
    .login-button{
      height: 46px;
      width: 100%;
      background: #fcdf20;
      border-radius: 4px;
      cursor: pointer;
      transition: all .2s;
      &_text{
        margin: 0 auto;
        height: 100%;
        width: 97%;
        background: #333;
        border-radius: 4px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 23px;
        font-weight: bold;
        letter-spacing: 6px;
      }
      &:active{
        opacity: 0.9;
      }
    }
    &_title{
      width: 100%;
      font-size: 20px;
      color: #333;
      font-weight: bold;
    }
    &_English{
      font-size: 40px;
      color: #333333;
      font-weight: bold;
      text-shadow: 2px 0px 2px #fcdf20;
      font-family: Roboto, San Francisco,;
    }
    .validateCode{
      // border: 1px solid #DCDFE6;
      /deep/ .el-input__inner{
        width: 98%;
      }
    }
    .remember-box{
      margin-bottom: 20px;
      /deep/ .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{
        background-color: #fcdf20;
        border-color: #333;
        color: #333;
      }
      /deep/ .el-checkbox__input.is-checked+.el-checkbox__label{
        // color: #fcdf20;
        color: #333;
      }
      // 初始化复选框边框颜色覆盖
      // /deep/ .el-checkbox__inner{
      //   border: 1px solid #333;
      // }
      /deep/ .el-checkbox__input.is-focus .el-checkbox__inner{
        color: #fcdf20;
        border-color: #333; 
      }
      /deep/ .el-checkbox__inner:hover{
        border-color: #fcdf20;; 
      }
      //记住密码复选框 √ 的颜色
      /deep/ .el-checkbox__inner::after{
        border: 1px solid #333;
        border-left: 0;
        border-top: 0;
      }
    }
  }
  /deep/ .el-input-group__append, .el-input-group__prepend{
    padding: 0px;
  }
}
</style>